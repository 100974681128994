import { Appearance } from '@stripe/stripe-js'

import { OperableType } from '../types/shipments'
import {
  BrakeType, ConditionType,
  DisclosureType,
  FuelType,
  OdometerUnit, SalvageType,
  Sex,
  VehicleBodyType,
  VehicleType
} from '../types/transactions'

// Whether or not to request the prod stripe publishable secret
export const USE_PROD_STRIPE = true

// Whether or not to use stripe (temporarily)
export const USE_STRIPE = true

// Whether or not we allow anonymous users (AKA usage without creating an account)
export const ALLOW_ANONYMOUS_USERS = false

// Whether or not we allow users to share transactions in the middle
export const ALLOW_TRANSACTION_SHARING = true

// make fees and transaction cost to be 0 for testing
export const USE_ZERO_FEES = false

// /// skip dmv steps variable
export const SKIP_DMV_PAGE = false

// limit the stripe verification sessions per dealership (5 per dealership)
export const LIMIT_VERIFICATION_SESSIONS = true

// API route
// NOTE: When using Local Firebase Functions we need to route through 10.0.2.2 for the Android Emulator!
export const API_URL = 'https://api-k7xmp3ea7a-uc.a.run.app/api/v1'
// export const API_URL = 'http://localhost:3000/api/v1';
// export const API_URL = 'https://api-dev-1012168475022.us-central1.run.app'
// "https://api-k7xmp3ea7a-uc.a.run.app/api/v1";
// "http://10.217.213.182:3000/api/v1";
// "http://192.168.1.16:3000/api/v1";
// "https://api-k7xmp3ea7a-uc.a.run.app/api/v1"; // ACTUAL BACKEND NOW
// "https://us-central1-kuaay-app.cloudfunctions.net/webApi/api/v1";
// "http://192.168.0.8:5001/kuaay-app/us-central1/webApi/api/v1";
// "http://10.0.2.2:5001/kuaay-app/us-central1/webApi/api/v1";
// "https://us-central1-kuaay-app.cloudfunctions.net/webApi/api/v1";

// TODO: USE THESE FROM THE .env FILE
// TODO: Rotate schedule lol
// NOTE: CHECK! The API key below is the Web API key from `Projects > Settings > General > Your Project`! (NOT Android)
export const apiKey = 'AIzaSyCD-tGGorLM1XevWWPOzK4TsESGrsLpF4w' // Constants.manifest.extra.API_KEY;
export const authDomain = 'kuaay-inc.firebaseapp.com' // Constants.manifest.extra.AUTH_DOMAIN;
// export const databaseId = "XXX"; // Constants.manifest.extra.DATABASE_ID;
export const projectId = 'kuaay-inc' // Constants.manifest.extra.PROJECT_ID;
export const storageBucket = 'kuaay-inc.appspot.com' // Constants.manifest.extra.STORAGE_BUCKET;
export const messageSenderId = '1012168475022' // Constants.manifest.extra.MESSAGE_SENDER_ID;
export const appId = '1:1012168475022:web:7dbf52774e06519260e4df' // Constants.manifest.extra.APP_ID;
export const measurementId = 'G-L5TTRR06HY' // Constants.manifest.extra.MEASUREMENT_ID;
export const facebookAppId = '2865915133681047' // Constants.manifest.extra.FACEBOOK_APP_ID;
export const androidClientId = 'XXX' // Constants.manifest.extra.ANDROID_CLIENT_ID;
export const iosClientId = 'XXX' // Constants.manifest.extra.IOS_CLIENT_ID;

// Stripe merchant IDs
export const STRIPE_APPLE_MERCHANT_ID = 'merchant.com.kuaay'
export const STRIPE_ANDROID_MERCHANT_ID = 'BCR2DN6T47ZZD6SD'

// Deep link URL
export const DEEP_LINK_URL = 'https://app.kuaay.com/#'

export const MANUAL_REFRESH_TOKEN_URL = `https://securetoken.googleapis.com/v1/token?key=${apiKey}`

// Anonymous signin boolean vals
export const ANON_SIGNIN_TRUE = 'true'
export const ANON_SIGNIN_FALSE = 'false'
// First time load boolean vals
export const HAS_ALREADY_LOADED_ONCE_TRUE = 'true'

// International car registration string
export const INTERNATIONAL_CAR_REG = 'international'

// Regex used for emails
// eslint-disable-next-line max-len
export const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i

// BASE64 PDF STRING:
export const BASE64_PDF_STR = 'data:application/pdf;base64,'

// Special spice for DiceBear avatar seeds
export const DICEBEARS_SALT = '#420blzit69!!nosc0p3'
export const DEFAULT_AVATAR_SIZE = 50

// Skip create transaction validation magic string
export const SKIP_VALIDATION_MAGIC_STRING = '~~~||~~~'

// Skip payment anytime with this magic string! (⚠️ CAUTION! ⚠️)
export const VEHICLE_VIN_PAY_SKIP_MAGIC_STRING = '~|~|~|~|~|~|~|~|~'

// Calendar constants
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

// User info constants
export const SEXES: Sex[] = ['Male', 'Female']

// Vehicle info constants
export const VEHICLE_TYPES: VehicleType[] = ['Car/SUV', 'Motorcycle', 'Motorhome'] // Not currently alloweed: "Truck"
export const VEHICLE_TYPE = { MotorHome: 'Motorhome', Motorcycle: 'Motorcycle', SUV: 'Car/SUV' }
export const VEHICLE_BODY_TYPES: VehicleBodyType[] = [
  'SUV',
  'Sedan',
  'Pickup',
  'Van',
  'Convertible',
  'Wagon',
  'Hatchback',
  'Coupe'
]
export const SHIPMENT_OPERABLE_TYPES: OperableType[] = [
  OperableType.OPERABLE,
  OperableType.INOPERABLE
]
export const BRAKE_TYPE: BrakeType[] = ['Hydraulic', 'Air', 'Other']
export const ODOMETER_TYPES: OdometerUnit[] = ['miles', 'kilometers', 'hours']
export const ODOMETER_TYPES_ABBR: string[] = ['mi', 'km', 'hours']
export const ODOMETER_DISCLOSURE_TYPES: DisclosureType[] = [
  'Reflects the actual mileage',
  'Reflects the mileage in excess of the odometer’s mechanical limits',
  'Is not the actual mileage - Odometer Discrepancy'
]
export const FUEL_TYPES: FuelType[] = [
  'Gas',
  'Diesel',
  'Propane',
  'Hybrid',
  'Electric',
  'Other'
]
export const CONDITION_TYPES: ConditionType[] = ['New', 'Used', 'Rebuilt']
export const SALVAGE_TYPES: SalvageType[] = [
  'Salvage',
  'Salvage and Rebuilt',
  'Declared total loss by insurer',
  'None/Not Applicable'
]

// States infos
export const US_STATES = [
  ['Arizona', 'AZ'],
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY']
]

// US driver's license fields
// See: https://www.dynamsoft.com/blog/imaging/extract-data-pdf417-driver-licenses/
// AAMVA2016, driver license abbreviation-description map
export const US_DRIVERS_LICENSE_FIELDS: { [key: string]: { description: string, name: string } } = {
  DCA: { description: 'Jurisdiction-specific vehicle class', name: '' },
  DBA: { description: 'Expiry Date', name: 'expiryDate' },
  DCS: { description: 'Last Name', name: 'lastName' },
  DAC: { description: 'First Name', name: 'firstName' },
  DBD: { description: 'Issue Date', name: 'issueDate' },
  DBB: { description: 'Birth Date', name: 'dateOfBirth' },
  DBC: { description: 'Gender', name: 'sex' },
  DAY: { description: 'Eye Color', name: 'eyeColor' },
  DAU: { description: 'Height', name: 'height' },
  DAG: { description: 'Street', name: 'address' },
  DAI: { description: 'City', name: 'city' },
  DAJ: { description: 'State', name: 'state' },
  DAK: { description: 'Zip', name: 'zip' },
  DAQ: { description: 'License Number', name: 'license' },
  DCF: { description: 'Document Discriminator', name: '' },
  DCG: { description: 'Issue Country', name: 'country' },
  DAH: { description: 'Street 2', name: 'address2' },
  DAZ: { description: 'Hair Color', name: '' },
  DCI: { description: 'Place of birth', name: '' },
  DCJ: { description: 'Audit information', name: '' },
  DCK: { description: 'Inventory Control Number', name: '' },
  DBN: { description: 'Alias / AKA Family Name', name: '' },
  DBG: { description: 'Alias / AKA Given Name', name: '' },
  DBS: { description: 'Alias / AKA Suffix Name', name: '' },
  DCU: { description: 'Name Suffix', name: '' },
  DCE: { description: 'Physical Description Weight Range', name: '' },
  DCL: { description: 'Race / Ethnicity', name: '' },
  DCM: { description: 'Standard vehicle classification', name: '' },
  DCN: { description: 'Standard endorsement code', name: '' },
  DCO: { description: 'Standard restriction code', name: '' },
  DCP: { description: 'Jurisdiction-specific vehicle classification description', name: '' },
  DCQ: { description: 'Jurisdiction-specific endorsement code description', name: '' },
  DCR: { description: 'Jurisdiction-specific restriction code description', name: '' },
  DDA: { description: 'Compliance Type', name: '' },
  DDB: { description: 'Card Revision Date', name: '' },
  DDC: { description: 'HazMat Endorsement Expiration Date', name: '' },
  DDD: { description: 'Limited Duration Document Indicator', name: '' },
  DAW: { description: 'Weight(pounds)', name: '' },
  DAX: { description: 'Weight(kilograms)', name: '' },
  DDH: { description: 'Under 18 Until', name: '' },
  DDI: { description: 'Under 19 Until', name: '' },
  DDJ: { description: 'Under 21 Until', name: '' },
  DDK: { description: 'Organ Donor Indicator', name: '' },
  DDL: { description: 'Veteran Indicator', name: '' },
  // old standard
  DAA: { description: 'Customer Full Name', name: '' },
  DAB: { description: 'Customer Last Name', name: '' },
  DAE: { description: 'Name Suffix', name: '' },
  DAF: { description: 'Name Prefix', name: '' },
  DAL: { description: 'Residence Street Address1', name: '' },
  DAM: { description: 'Residence Street Address2', name: '' },
  DAN: { description: 'Residence City', name: '' },
  DAO: { description: 'Residence Jurisdiction Code', name: '' },
  DAR: { description: 'License Classification Code', name: '' },
  DAS: { description: 'License Restriction Code', name: '' },
  DAT: { description: 'License Endorsements Code', name: '' },
  DAV: { description: 'Height in CM', name: '' },
  DBE: { description: 'Issue Timestamp', name: '' },
  DBF: { description: 'Number of Duplicates', name: '' },
  DBH: { description: 'Organ Donor', name: '' },
  DBI: { description: 'Non-Resident Indicator', name: '' },
  DBJ: { description: 'Unique Customer Identifier', name: '' },
  DBK: { description: 'Social Security Number', name: '' },
  DBM: { description: 'Social Security Number', name: '' },
  DCH: { description: 'Federal Commercial Vehicle Codes', name: '' },
  DBR: { description: 'Name Suffix', name: '' },
  PAA: { description: 'Permit Classification Code', name: '' },
  PAB: { description: 'Permit Expiration Date', name: '' },
  PAC: { description: 'Permit Identifier', name: '' },
  PAD: { description: 'Permit IssueDate', name: '' },
  PAE: { description: 'Permit Restriction Code', name: '' },
  PAF: { description: 'Permit Endorsement Code', name: '' },
  ZVA: { description: 'Court Restriction Code', name: '' },
  DAD: { description: 'Middle Name', name: 'middleName' }
}
// Might want to switch to these in the near future
export const US_DRIVERS_LICENSE_FIELDS_V2 = [
  { abbreviation: 'DAA', description: 'Full Name' },
  { abbreviation: 'DAB', description: 'Last Name' },
  { abbreviation: 'DAB', description: 'Family Name' },
  { abbreviation: 'DAC', description: 'First Name' },
  { abbreviation: 'DAC', description: 'Given Name' },
  { abbreviation: 'DAD', description: 'Middle Name or Initial' },
  // { abbreviation: "DAD", description: "Middle Name" },
  { abbreviation: 'DAE', description: 'Name Suffix' },
  { abbreviation: 'DAF', description: 'Name Prefix' },
  { abbreviation: 'DAG', description: 'Mailing Street Address1' },
  { abbreviation: 'DAH', description: 'Mailing Street Address2' },
  { abbreviation: 'DAI', description: 'Mailing City' },
  { abbreviation: 'DAJ', description: 'Mailing Jurisdiction Code' },
  { abbreviation: 'DAK', description: 'Mailing Postal Code' },
  { abbreviation: 'DAL', description: 'Residence Street Address1' },
  { abbreviation: 'DAM', description: 'Residence Street Address2' },
  { abbreviation: 'DAN', description: 'Residence City' },
  { abbreviation: 'DAO', description: 'Residence Jurisdiction Code' },
  { abbreviation: 'DAP', description: 'Residence Postal Code' },
  { abbreviation: 'DAQ', description: 'License or ID Number' },
  { abbreviation: 'DAR', description: 'License Classification Code' },
  { abbreviation: 'DAS', description: 'License Restriction Code' },
  { abbreviation: 'DAT', description: 'License Endorsements Code' },
  { abbreviation: 'DAU', description: 'Height in FT_IN' },
  { abbreviation: 'DAV', description: 'Height in CM' },
  { abbreviation: 'DAW', description: 'Weight in LBS' },
  { abbreviation: 'DAX', description: 'Weight in KG' },
  { abbreviation: 'DAY', description: 'Eye Color' },
  { abbreviation: 'DAZ', description: 'Hair Color' },
  { abbreviation: 'DBA', description: 'License Expiration Date' },
  { abbreviation: 'DBB', description: 'Date of Birth' },
  { abbreviation: 'DBC', description: 'Sex' },
  { abbreviation: 'DBD', description: 'License or ID Document Issue Date' },
  { abbreviation: 'DBE', description: 'Issue Timestamp' },
  { abbreviation: 'DBF', description: 'Number of Duplicates' },
  { abbreviation: 'DBG', description: 'Medical Indicator Codes' },
  { abbreviation: 'DBH', description: 'Organ Donor' },
  { abbreviation: 'DBI', description: 'Non-Resident Indicator' },
  { abbreviation: 'DBJ', description: 'Unique Customer Identifier' },
  { abbreviation: 'DBK', description: 'Social Security Number' },
  { abbreviation: 'DBL', description: 'Date Of Birth' },
  { abbreviation: 'DBM', description: 'Social Security Number' },
  { abbreviation: 'DBN', description: 'Full Name' },
  { abbreviation: 'DBO', description: 'Last Name' },
  { abbreviation: 'DBO', description: 'Family Name' },
  { abbreviation: 'DBP', description: 'First Name' },
  { abbreviation: 'DBP', description: 'Given Name' },
  { abbreviation: 'DBQ', description: 'Middle Name' },
  { abbreviation: 'DBQ', description: 'Middle Name or Initial' },
  { abbreviation: 'DBR', description: 'Suffix' },
  { abbreviation: 'DBS', description: 'Prefix' },
  { abbreviation: 'DCA', description: 'Virginia Specific Class' },
  { abbreviation: 'DCB', description: 'Virginia Specific Restrictions' },
  { abbreviation: 'DCD', description: 'Virginia Specific Endorsements' },
  { abbreviation: 'DCE', description: 'Physical Description Weight Range' },
  { abbreviation: 'DCF', description: 'Document Discriminator' },
  { abbreviation: 'DCG', description: 'Country territory of issuance' },
  { abbreviation: 'DCH', description: 'Federal Commercial Vehicle Codes' },
  { abbreviation: 'DCI', description: 'Place of birth' },
  { abbreviation: 'DCJ', description: 'Audit information' },
  { abbreviation: 'DCK', description: 'Inventory Control Number' },
  { abbreviation: 'DCL', description: 'Race Ethnicity' },
  { abbreviation: 'DCM', description: 'Standard vehicle classification' },
  { abbreviation: 'DCN', description: 'Standard endorsement code' },
  { abbreviation: 'DCO', description: 'Standard restriction code' },
  {
    abbreviation: 'DCP',
    description: 'Jurisdiction specific vehicle classification description'
  },
  { abbreviation: 'DCQ', description: 'Jurisdiction-specific' },
  {
    abbreviation: 'DCR',
    description: 'Jurisdiction specific restriction code description'
  },
  { abbreviation: 'DCS', description: 'Family Name' },
  { abbreviation: 'DCS', description: 'Last Name' },
  { abbreviation: 'DCT', description: 'Given Name' },
  { abbreviation: 'DCT', description: 'First Name' },
  { abbreviation: 'DCU', description: 'Suffix' },
  { abbreviation: 'DDA', description: 'Compliance Type' },
  { abbreviation: 'DDB', description: 'Card Revision Date' },
  { abbreviation: 'DDC', description: 'HazMat Endorsement Expiry Date' },
  { abbreviation: 'DDD', description: 'Limited Duration Document Indicator' },
  { abbreviation: 'DDE', description: 'Family Name Truncation' },
  { abbreviation: 'DDF', description: 'First Names Truncation' },
  { abbreviation: 'DDG', description: 'Middle Names Truncation' },
  { abbreviation: 'DDH', description: 'Under 18 Until' },
  { abbreviation: 'DDI', description: 'Under 19 Until' },
  { abbreviation: 'DDJ', description: 'Under 21 Until' },
  { abbreviation: 'DDK', description: 'Organ Donor Indicator' },
  { abbreviation: 'DDL', description: 'Veteran Indicator' },
  { abbreviation: 'PAA', description: 'Permit Classification Code' },
  { abbreviation: 'PAB', description: 'Permit Expiration Date' },
  { abbreviation: 'PAC', description: 'Permit Identifier' },
  { abbreviation: 'PAD', description: 'Permit IssueDate' },
  { abbreviation: 'PAE', description: 'Permit Restriction Code' },
  { abbreviation: 'PAF', description: 'Permit Endorsement Code' },
  { abbreviation: 'ZVA', description: 'Court Restriction Code' }
]

export const PAYMENT_TRANSFER_HISTORY_MAPPING = {
  BUYER_PROTECTION: {
    none: [],
    pending: [],
    verified: ['You have verified vehicle condition.'],
    release_payment_pending: ['Kuaay has processed to release the funds to the Seller.'],
    release_payment_ack: ['The funds have been released to the Seller.'],
    refund_pending: ['Kuaay has processed to refund the payment to you.'],
    refund_ack: ['Kuaay has refunded the payment to you.'],
    completed: ['This transaction has been completed.'],
    failed: ['This transaction has been marked as FAILED. Kuaay will refund the payment to you in 48 hours.'],
    transfer_payment: ['You have started a payment transfer.'],
    transfer_payment_ack: ['Kuaay have held the funds. It will be release to the Seller when the vehicle condition has been verified.'],
    vehicle_shipped: ['The vehicle has been delivered to you location. Vehicle condition will be marked as verified automatically after 24 hours.']
  },
  BUYER_NON_PROTECTION: {
    none: [],
    pending: [],
    verified: [],
    release_payment_pending: ['Kuaay has processed to release the funds to the Seller.'],
    release_payment_ack: ['The funds have been released to the Seller.'],
    refund_pending: [],
    refund_ack: [],
    completed: ['This transaction has been completed.'],
    failed: ['This transaction has been marked as FAILED.'],
    transfer_payment: ['You have started a payment transfer.'],
    transfer_payment_ack: ['Kuaay have held the funds. It will be release to the Seller within 48 hours.'],
    vehicle_shipped: []
  },
  SELLER_PROTECTION: {
    none: [],
    pending: [],
    verified: ['The Buyer has verified vehicle condition.'],
    release_payment_pending: ['Kuaay has processed to release the funds to your bank account. You will receive it within 48 hours.'],
    release_payment_ack: ['Kuaay has released the funds to your bank account.'],
    refund_pending: ['Kuaay has processed to refund the payment to the Buyer.'],
    refund_ack: ['Kuaay has refunded the payment to the Buyer.'],
    completed: ['This transaction has been completed.'],
    failed: ['This transaction has been marked as FAILED. Kuaay will return the funds to the Buyer in 48 hours.'],
    transfer_payment: ['The Buyer has started a payment transfer.'],
    transfer_payment_ack: ['Kuaay has received the Buyer\'s payment. Once the Buyer verifies the vehicle condition, the funds will be release to you.'],
    vehicle_shipped: ['The vehicle has been delivered to the Buyer\'s location. Vehicle condition will be marked as verified automatically after 24 hours.']
  },
  SELLER_NON_PROTECTION: {
    none: [],
    pending: [],
    verified: [],
    release_payment_pending: ['Kuaay has processed to release the funds to your bank account. You will receive it within 48 hours.'],
    release_payment_ack: ['Kuaay has released the funds to your bank account.'],
    refund_pending: [],
    refund_ack: [],
    completed: ['This transaction has been completed.'],
    failed: ['This transaction has been marked as FAILED.'],
    transfer_payment: ['The Buyer has started a payment transfer.'],
    transfer_payment_ack: ['Kuaay has received the Buyer\'s payment.'],
    vehicle_shipped: []
  }
}

export const STORAGE_KEY = {
  KUAAY_USER_STATE: 'KUAAY_USER_STATE',
  KUAAY_CREATE_TRANSACTION: 'KUAAY_CREATE_TRANSACTION',
  KUAAY_CREATE_SHIPMENT: 'KUAAY_CREATE_SHIPMENT',
  KUAAY_CREATE_WARRANTY: 'KUAAY_CREATE_WARRANTY'

}

export const ZIP_CODE_REGEX = /^\d{5}$/
export const PHONE_NUMBER_REGEX = /^[\+]?[0-9]?[ ]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
export const NUMBER_REGEX = /^(0|[1-9]\d*)$/
export const YEAR_REGEX = /^\d{4}$/

export const STRIPE_ELEMENT_OPTIONS_LIGHT = {
  style: {
    base: {
      fontSize: '16px',
      color: '#222B45',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#5f6574'
      }
    },
    invalid: {
      color: '#FF463D'
    }
  }
}

export const STRIPE_ELEMENT_OPTIONS_DARK = {
  style: {
    base: {
      fontSize: '16px',
      color: '#FFFFFF',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#8e94a3'
      }
    },
    invalid: {
      color: '#FF463D'
    }
  }
}

export const STRIPE_APPEARANCE_DARK: Appearance = {
  theme: 'none',
  labels: 'floating',
  rules: {
    '.Input': {
      fontSize: '16px',
      letterSpacing: '0.025em',
      boxShadow: 'none !important',
      backgroundColor: '#1A2138',
      color: '#FFFFFF',
      border: 'solid 1px #3366FF',
      outline: 'none'
    },
    '.Input:focus': {
      backgroundColor: '#222B45'
    },
    '.Input:active': {
      backgroundColor: '#222B45'
    },
    // '.Input::placeholder': {
    //   color: 'transparent'
    // },
    '.Label': {
      color: '#8e94a3'
    },
    '.Tab': {
      border: '1px solid #E0E6EB',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)'
    },

    '.Tab:hover': {
      color: 'var(--colorText)'
    },

    '.Tab--selected': {
      borderColor: '#E0E6EB',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)'
    },

    '.Input--invalid': {
      boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)'
    }
  }
}

export const STRIPE_APPEARANCE_LIGHT: Appearance = {
  theme: 'none',
  labels: 'floating',
  rules: {
    '.Input': {
      fontSize: '16px',
      letterSpacing: '0.025em',
      boxShadow: 'none !important',
      backgroundColor: 'transparent',
      color: '#222B45',
      border: 'solid 1px #3366FF',
      outline: 'none'
    },
    // '.Input::placeholder': {
    //   color: 'transparent'
    // },
    '.Label': {
      color: '#5f6574'
    },
    '.Tab': {
      border: '1px solid #E0E6EB',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)'
    },

    '.Tab:hover': {
      color: 'var(--colorText)'
    },

    '.Tab--selected': {
      borderColor: '#E0E6EB',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)'
    },

    '.Input--invalid': {
      boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)'
    }
  }
}

export const DEFAULT_CHECKOUT_ERROR_MESSAGE = 'Failed to checkout. Please try again later'

export const DOCUMENT_PREPARATION_HOURS = 48

const BEGIN_YEAR = 1886
const CURR_YEAR = new Date().getFullYear()
export const AVAILABLE_VEHICLE_YEARS = new Array(CURR_YEAR - BEGIN_YEAR + 1).fill(CURR_YEAR).map((it, idx) => it - idx)
